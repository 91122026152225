/*
 * @Description: 全局变量
 */
exports.install = function (Vue) {
  Vue.prototype.$apiBaseUrl = 'https://www.toysnewhome.cn/api'   // 接口地址
  Vue.prototype.$target = "https://picture.toysmodel.cn:11443/Inf"  // 远程文件地址
  // 封装提示成功的弹出框
  Vue.prototype.notifySucceed = function (msg) {
    this.$notify({
      title: "成功",
      message: msg,
      type: "success",
      offset: 100
    });
  };
  // 封装提示失败的弹出框
  Vue.prototype.notifyError = function (msg) {
    this.$notify.error({
      title: "错误",
      message: msg,
      offset: 100
    });
  };
}