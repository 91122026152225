<!--
 * @Description: 项目根组件
 -->
<template>
  <div id="app" name="app">
    <CustomerService />
    <el-container style="background: #ececec">
      <!-- 顶栏容器 -->
      <!-- 顶部导航 -->
      <TopBar />
      <!-- 顶部导航结束 -->
      <el-header height="150px">
        <div class="header">
          <!-- 顶部Logo 和 搜索 -->
          <el-row style="height: 100px">
            <!-- Logo -->
            <el-col :span="4">
              <div class="logo">
                <router-link to="/">
                  <img src="./assets/imgs/logo.png" alt />
                </router-link>
              </div>
            </el-col>
            <!-- 搜索 -->
            <el-col :span="12">
              <div class="so">
                <el-input
                  placeholder="请输入搜索内容"
                  v-model="keyword"
                  size="large"
                  class="wordSearchInput"
                >
                </el-input>
                <el-upload
                  name="image"
                  action="https://www.toysnewhome.cn/api/web/goods/image_search"
                  :limit="1"
                  :file-list="searchImage"
                  :on-exceed="handleExceed"
                  :on-success="handleSearchImage"
                >
                  <el-button
                    size="mini"
                    icon="el-icon-camera el-input__icon"
                    class="imageSearchIcon"
                  ></el-button>
                </el-upload>
                <el-button
                  @click="searchClick"
                  size="mini"
                  class="wordSearchButton"
                  >搜索</el-button
                >
              </div>
            </el-col>

            <!-- 购物车 -->
            <el-col :span="3">
              <div :class="getNum > 0 ? 'shopCart-full shopCart' : 'shopCart'">
                <router-link to="/shoppingCart">
                  <i class="el-icon-shopping-cart-full"></i>
                  <span class="num">购物车({{ getNum }})</span>
                </router-link>
              </div>
            </el-col>
            <!-- APP二维码 -->
            <el-col :span="5">
              <div class="qrcode">
                <div class="qrcode-text">毛绒之家APP</div>
                <el-image
                  :src="require('@/assets/imgs/qrcode1.png')"
                  :preview-src-list="[require('@/assets/imgs/qrcode1.png')]"
                ></el-image>
              </div>
            </el-col>
          </el-row>
          <!-- 顶部Logo 和 搜索结束 -->
          <!-- 菜单 -->
          <el-row class="menu">
            <el-col :span="2" class="all-type">
              <i
                class="el-icon-s-grid"
                style="position: relative; top: 1px"
              ></i>
              <span style="font-weight: bold; color: #000">全部类目</span>
            </el-col>
            <el-col :span="21" style="margin-left: 50px">
              <el-menu
                :default-active="activeIndex"
                mode="horizontal"
                active-text-color="#FA3739"
                router
              >
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/df">代发商品</el-menu-item>
                <el-menu-item index="/goods">全部商品</el-menu-item>
                <el-menu-item index="/shopList">市场导航</el-menu-item>
                <el-menu-item index="/software">应用下载</el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 主要区域容器 -->
      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <Footer />
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import CustomerService from "./components/CustomerService";

import { mapGetters } from "vuex";

export default {
  components: {
    TopBar,
    Footer,
    CustomerService,
  },
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  data() {
    return {
      activeIndex: "", // 头部导航栏选中的标签
      keyword: "", // 搜索条件,
      searchImage: []
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getNum"]),
  },
  methods: {
    /**
     * 以图搜图超出数量
     */
    handleExceed() {
      this.notifyError("请选择单张图片!");
    },
    /**
     * 以图搜图交互
     */
    handleSearchImage(response) {
      if (response && response.code === 200) {
        const data = response.data
        // 添加loading响应
        
        this.$router.push({ path: "/search", query: { data: JSON.stringify(data) } });
      } else {
        this.notifyError('无数据');
      }
      this.searchImage = []
    },
    /**
     * 点击按钮搜索商品
     */
    searchClick() {
      if (this.keyword) {
        this.$router.push({ path: "/search", query: { keyword: this.keyword } });
      }
    },
  },
};
</script>

<style>
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
#app .el-header {
  padding: 10px 0 0 0;
  background: #fff;
  width: 100%;
}
#app .el-header .header {
  width: 1225px;
  text-align: center;
  margin: 0 auto;
}
#app .el-main {
  min-height: 300px;
  margin-bottom: 20px;
  padding: 10px 0 0 0;
  background: #fff;
}
#app .el-footer {
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
/* 全局CSS END */

/* 顶栏容器CSS */
.el-header .logo {
  margin-top: 25px;
  margin-right: 15px;
}
/* 搜索框 */
.el-upload-list {
  display: none;
  width: 0;
  height: 0;
}
.el-header .so {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* height: 40px; */
}
.so .el-input__icon {
  font-size: 22px;
  /* font-weight: bold; */
  color: #f53f3f;
}
.so .el-button:focus,
.so .el-button:hover {
  border: 1px solid #f53f3f;
}
.wordSearchInput .el-input__inner {
  border: 1px solid #f53f3f;
  border-radius: 0;
}
.wordSearchInput .el-input__inner:hover,
.wordSearchInput .el-input__inner:focus {
  border: 1px solid #f53f3f;
}
.el-header .imageSearchIcon {
  position: relative;
  width: 50px;
  left: -2px;
}
.imageSearchIcon.el-button--mini {
  height: 40px;
  padding: 0 10px;
  border-radius: 0;
  border: 1px solid #f53f3f;
  border-left: 0;
}
.imageSearchIcon.el-button--mini:hover,
.imageSearchIcon.el-button--mini:focus {
  border-left: 0;
  background: #fff;
}
.wordSearchButton {
  position: relative;
  left: -2px;
}
.wordSearchButton.el-button--mini {
  width: 100px;
  border-radius: 0;
  border: 1px solid #f53f3f;
  background: #f53f3f;
  color: #fff;
  letter-spacing: 0.5em;
  font-size: 14px;
}
.wordSearchButton.el-button--mini:hover,
.wordSearchButton.el-button--mini:focus {
  background: #f53f3f;
  color: #fff;
  cursor: pointer;
}
/* 购物车 */
.el-header .shopCart {
  width: 90%;
  line-height: 50px;
  margin: 23px 0 0 20px;
}
.el-header .el-icon-shopping-cart-full {
  font-size: 30px;
}
.el-header .num {
  position: relative;
  top: -5px;
  margin-left: 5px;
}
.el-header .shopCart:hover {
  background: #fff;
}
.el-header .shopCart:hover a {
  color: #ff6700;
}
.el-header .shopCart-full {
  color: #ff6700;
}
.shopCart-full .el-icon-shopping-cart-full {
  color: #ff6700;
}
.shopCart-full .num {
  color: #ff6700;
}
.el-header .qrcode {
  width: 120px;
  text-align: center;
  position: absolute;
  left: 1000px;
  z-index: 999;
}
.el-header .qrcode-text {
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.el-header .el-image {
  width: 100px;
  height: 100px;
}
/* 菜单 */
.el-header .menu {
  font-size: 16px;
  line-height: 16px;
  color: #fa3739;
}
.el-header .all-type {
  height: 40px;
  line-height: 40px;
}
.el-header .all-type .el-icon-s-grid {
  font-size: 20px;
  margin: 0 5px 0 0;
}
.el-header .el-menu {
  max-width: 100%;
  margin: 0 auto;
}
.el-header .el-menu .el-menu-item {
  font-size: 16px;
  color: #fa3739;
  font-weight: bold;
  margin-right: 35px;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0 !important;
}
.el-menu--horizontal > .el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
}
.el-header .el-menu .el-menu-item:hover {
  color: #fa3739 !important;
}
/* 顶栏容器CSS END */
</style>
