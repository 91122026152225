<!--
 * @Description: 用户注册组件
 * @Date: 2020-02-19 22:20:35
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:34
 -->
<template>
  <div id="register">
    <el-dialog title="注册" width="500px" center :visible.sync="isRegister">
      <el-form
        :model="RegisterForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="请输入用户名"
            v-model="RegisterForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-view"
            type="password"
            placeholder="请输入密码"
            v-model="RegisterForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPass">
          <el-input
            prefix-icon="el-icon-view"
            type="password"
            placeholder="请再次输入密码"
            v-model="RegisterForm.confirmPass"
          ></el-input>
        </el-form-item>
        <el-form-item prop="nickname">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入姓名"
            v-model="RegisterForm.nickname"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input
            prefix-icon="el-icon-mobile"
            placeholder="请输入手机号"
            v-model="RegisterForm.mobile"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="doRegister"
            style="width: 100%"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "MyRegister",
  props: ["register"],
  data() {
    /**
     * 用户名校验
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名!"));
      }
      const usernameRule = /^[a-zA-Z0-9][a-zA-Z0-9_]{4,15}$/;
      if (usernameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("用户名请用字母或数字开头,长度5-16之间,允许字母数字下划线"));
      }
    };
    /**
     * 密码校验
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入密码!"));
      }
      const passwordRule = /^[a-zA-Z0-9]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("密码请以字母或数字开头,长度6-18之间,允许字母数字和下划线!")
        );
      }
    };
    /**
     * 确认密码校验
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    let validateConfirmPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入确认密码!"));
      }
      if (
        this.RegisterForm.password != "" &&
        value === this.RegisterForm.password
      ) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致!"));
      }
    };
    return {
      isRegister: false, // 控制注册组件是否显示
      RegisterForm: {
        username: "",
        password: "",
        confirmPass: "",
        nickname: "",
        mobile: "",
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        confirmPass: [{ validator: validateConfirmPass, trigger: "blur" }],
      },
    };
  },
  watch: {
    /**
     * 监听父组件传过来的register变量，设置this.isRegister的值
     * @param {*} val 
     */
    register: function (val) {
      if (val) {
        this.isRegister = val;
      }
    },
    /**
     * 监听this.isRegister变量的值，更新父组件register变量的值
     * @param {*} val 
     */
    isRegister: function (val) {
      if (!val) {
        this.$refs["ruleForm"].resetFields();
        this.$emit("fromChild", val);
      }
    },
  },
  methods: {
    /**
     * 注册
     */
    doRegister() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.$apiBaseUrl + "/web/user/register", this.RegisterForm)
            .then((res) => {
              const data = res.data;
              if (data.code === 1) {
                this.isRegister = false;
                this.notifySucceed("注册成功，请返回登录！");
              } else {
                this.notifyError(data.msg);
              }
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style></style>
